import SERVICE_URL from "../config/API_DATA";
import HttpPost from "../http/httppost.js";
import Logger from "./logger.js";
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";

export default class UpdateUser {
    static buildRequest(pUserObj) {
        let returnValue = null;
        try {
            let body = {
                userid: pUserObj.userid,
                emailid: pUserObj.emailid,
                firstname: pUserObj.firstname,
                lastname: pUserObj.lastname,
                created_at: pUserObj.created_at,
                roles: [pUserObj.roles.roleName],
                isactive: pUserObj.isactive,
            };

            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_UPDATE_USER,
                "error in building body for Update User api",
                err.toString(),
                "buildRequest",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(
                SERVICE_URL.UPDATE_USER,
                pReqOptions
            );
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_UPDATE_USER,
                "error in getting Response from  Update User api ",
                err.toString(),
                "getResponse",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
<template>
  <div>
    <div>
      <ToggleButton
        @change="isConfirm ? toggleAlertModal() : onChange()"
        :width="80"
        v-model="status"
        color="#82C7EB"
        :sync="true"
        :labels="label"
      >
      </ToggleButton>
    </div>
    <cAlertModal
      v-if="isShowAlertModal"
      :key="'alertModal'"
      :buttonList="getModalBtns()"
      :status="2"
      :show="isShowAlertModal"
      :desc="buttonDesc"
    ></cAlertModal>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
import { COMPONENT_NAME } from "../../util/constants.js";
import BaseConfiguration from "../../config/base.js";
import Logger from "../../services/logger";

import cAlertModal from "./cAlertModal";
import { LOG_TYPE, FILE_NAME } from "../../config/API_DATA";
export default {
  name: COMPONENT_NAME.C_STATUS_BUTTON,

  components: {
    ToggleButton,
    cAlertModal,
  },

  props: {
    isConfirm: {
      type: Boolean,
      default: true,
    },

    defaultStatus: {
      type: Boolean,
      default: false,
    },
    label: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Boolean,
      default: true,
    },
    buttonDesc: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      isShowAlertModal: false,
      status: false,
    };
  },

  methods: {
    getModalBtns() {
      let retValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getModalBtns");
        retValue = [
          {
            name: this.$t("assignPricing.no"),
            func: this.onCancel,

            color: "success",
          },
          {
            name: this.$t("assignPricing.yes"),
            func: this.onChange,
            color: "success",
          },
        ];
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.C_STATUS_BUTTON,
          "error in getModalBtns",
          err.toString(),
          "getModalBtns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    toggleAlertModal() {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleAlertModal");
        this.isShowAlertModal = !this.isShowAlertModal;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.C_STATUS_BUTTON,
          "error in toggleAlertModal",
          err.toString(),
          "toggleAlertModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onCancel() {
      try {
        this.status = !this.status;
        this.toggleAlertModal();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.C_STATUS_BUTTON,
          "error in onCancel",
          err.toString(),
          "onCancel",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onChange() {
      try {
        if (BaseConfiguration.isDebug) console.log("onChange");

        if (this.isConfirm) this.toggleAlertModal();

        this.$emit("change", this.status);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.C_STATUS_BUTTON,
          "error in onChange",
          err.toString(),
          "onChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },

  created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");

      if (this.defaultStatus != null) {
        this.status = this.defaultStatus;
      }
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.C_STATUS_BUTTON,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
};
</script>

<style></style>

import SERVICE_URL from "../config/API_DATA";
import HttpPost from "../http/httppost.js";
import Logger from "./logger.js";
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";

export default class ForgotPassword {
    static buildRequest(pMail) {
        let returnValue = null;
        try {
            let body = {
                "emailId": pMail
            };

            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_FORGOT_PASSWORD,
                "error in building body for ForgotPassword",
                err.toString(),
                "buildRequest",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(
                SERVICE_URL.FORGOT_PASSWORD,
                pReqOptions
            );
        } catch (err) {
            let body = Logger.buildRequest(
                FILE_NAME.API_FORGOT_PASSWORD,
                "error in getting Response from  ForgotPassword",
                err.toString(),
                "getResponse",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return returnValue;
    }
}
<template>
  <div class="user-status">
    <ValidationObserver v-slot="{ handleSubmit }">
      <CForm
        @submit.prevent="
          handleSubmit(isUserUpdate ? showModal : onAddUserFormSubmit)
        "
        autocomplete="off"
      >
        <CCard>
          <CCardHeader class="grey-header">
            <CRow class="flex-flow-column">
              <CCol>
                <strong>
                  {{ $t("userStatus.title") }}
                </strong>

                <!-- <span class="desc">
                        {{$t('userStatus.desc')}}

                    </span> -->
              </CCol>
              <p class="desc">{{ $t("userStatus.desc") }}</p>
            </CRow>
          </CCardHeader>

          <CCardBody class="card-w-shadow-row">
            <!-- <strong class="col mb-10px" v-if='selectedUserObj&&selectedUserObj.agencyName'>
             {{selectedUserObj.agencyName}}
           </strong> -->
            <CRow v-if="selectedUserObj && selectedUserObj.agencyName">
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/name.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="d-flex">
                  <label class="v-center">
                    <strong>{{ $t("userStatus.agencyName") }}</strong>
                  </label>
                </CCol>

                <CCol class="col">
                  <label class="v-center">
                    {{ selectedUserObj.agencyName }}
                  </label>
                </CCol>
              </div>
            </CRow>
            <CRow>
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/name.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="d-flex">
                  <label class="v-center">
                    <strong>{{ $t("userStatus.firstName") }}</strong>
                  </label>
                </CCol>

                <!-- <CCol class=" col "> -->
                <div class="col col">
                  <cTextInputComponent
                    class="textbox"
                    v-model="user.firstname"
                    :placeholder="$t('addUser.fName')"
                    :name="$t('addUser.fName')"
                    rules="required|noInitialSpace|max:30|onlyAlphabets"
                    :isAutoComplete="false"
                    @onInput="onEdit"
                  />
                </div>

                <!-- <label class="v-center">
                        {{user.firstname+" "+user.lastname}}
                    </label> -->
                <!-- </CCol> -->
              </div>
            </CRow>
            <CRow>
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/name.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="d-flex">
                  <label class="v-center">
                    <strong>{{ $t("userStatus.lastName") }}</strong>
                  </label>
                </CCol>

                <!-- <CCol class=" col "> -->
                <div class="col col">
                  <cTextInputComponent
                    class="textbox"
                    v-model="user.lastname"
                    :placeholder="$t('addUser.lName')"
                    :name="$t('addUser.lName')"
                    rules="noInitialSpace|max:30|onlyAlphabets"
                    :isAutoComplete="false"
                    @onInput="onEdit"
                  />
                </div>
                <!-- <label class="v-center">
                        {{user.firstname+" "+user.lastname}}
                    </label> -->
                <!-- </CCol> -->
              </div>
            </CRow>

            <CRow>
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/email.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="col">
                  <strong>{{ $t("userStatus.emailId") }}</strong>
                </CCol>
                <CCol class="col">
                  <label class="v-center">
                    {{ user.emailid }}
                  </label>
                </CCol>
              </div>
            </CRow>

            <CRow>
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/password.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="col">
                  <strong>{{ $t("userStatus.pwd") }}</strong>
                </CCol>
                <CCol class="col">
                  <label class="v-center"> ****************** </label>
                </CCol>
              </div>
            </CRow>

            <CRow>
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/role.svg" alt="" />
              </div>
              <div class="subtitile-details" style="align-items: center">
                <CCol class="col">
                  <strong>{{ $t("userStatus.role") }}</strong>
                </CCol>

                <CCol
                  v-if="userType && userType.code == 'agt'"
                  class="col overflow-unset"
                >
                  <cDropDown
                    key="agt"
                    :isB2BRole="true"
                    v-on:input="onRoleChange"
                    :selValue="user.roles"
                    :ctrlName="'roleName'"
                    :isShowCode="false"
                    :isMultiple="false"
                    :isSearchable="true"
                    :url="rolesServiceURL"
                    rules="required"
                    class="textbox"
                  />
                </CCol>

                <CCol v-else class="col">
                  <cDropDown
                    key="sys"
                    v-on:input="onRoleChange"
                    :isSystemRole="true"
                    :selValue="user.roles"
                    :ctrlName="'roleName'"
                    :isShowCode="false"
                    :isMultiple="false"
                    :isSearchable="true"
                    :url="rolesServiceURL"
                    rules="required"
                    class="textbox"
                  />
                </CCol>
              </div>
            </CRow>

            <CRow class="active-in-switch">
              <div class="approve-f-icon">
                <img src="@imgPath/img/svg/icons/status.svg" alt="" />
              </div>
              <div class="subtitile-details">
                <CCol class="col v-center">
                  <strong>{{ $t("userStatus.status") }}</strong>
                </CCol>

                <CCol
                  class="col row-productor d-flex-col-12 user-status-dropdown"
                >
                  <cStatusButton
                    v-if="user && user.status"
                    :defaultStatus="user.status.value"
                    :isConfirm="false"
                    @change="setUserStatus"
                    :buttonDesc="
                      $t('userStatus.confirmUpdate', {
                        fname: user.firstname,
                        lname: user.lastname,
                      })
                    "
                    :label="{
                      checked: $t('userStatus.activate'),
                      unchecked: $t('userStatus.deactivate'),
                    }"
                  >
                  </cStatusButton>

                  <!-- <cDropDown v-model="user.status" url="status" :isAllowEmpty="false" :ctrlCode="'value'" :selValue="user.status.name" :ctrlName="'name'" :isShowCode=false :isMultiple=false :isSearchable=true rules="required" /> -->
                </CCol>
              </div>
            </CRow>
          </CCardBody>

          <CCardFooter>
            <CRow class="mob-flex-column">
              <!--                <CCol class="v-center">-->
              <!--                    <a class="link-text">-->
              <!--                        Reset Password?-->
              <!--                    </a>-->
              <!--                </CCol>-->

              <CCol class="text-right mob-btn-center align-item-center d-flex">
                <CRow
                  class="d-flex justify-content-between align-item-center mob-pr-20px"
                >
                  <a
                    href="#"
                    @click="onPWResetConfm"
                    class="pl-10px c-sky-blue"
                    >{{ $t("userStatus.reset") }}</a
                  >
                  <cHelpTip
                    :content="$t('helpTip.userStatusResetpwd')"
                    class="right"
                  ></cHelpTip>
                  <span> </span>

                  <!-- <CButton color="danger" @click="navigateToAllUsers()" class="action-c-btn">
                            <img src="@imgPath/img/svg/icons/close.svg"> 

                            {{$t('userStatus.backBtn')}}
                        </CButton> -->

                  <!-- <CButton class="buttonspace action-c-btn" @click="showModalUserStatus(modalStatusUserSts)" color="success removewrap" >
                            <img src="@imgPath/img/svg/icons/correct.svg">

                            {{$t('userStatus.update')}}
                        </CButton> -->
                </CRow>
              </CCol>
              <CCol>
                <div class="main-cta-btn justify-content-end">
                  <CButton
                    @click="navigateToAllUsers()"
                    color="danger"
                    class="b-none"
                  >
                    <img
                      src="@imgPath/img/svg/icons/close.svg"
                      height="20px"
                      width="20px"
                      class="filter invert mr-10px"
                    />
                    {{ $t("addUser.cancel") }}
                  </CButton>
                  <CButton col="12" type="submit" color="success">
                    <img
                      src="@imgPath/img/svg/icons/correct.svg"
                      height="20px"
                      width="20px"
                      class="filter invert mr-10px"
                    />
                    {{
                      isUserUpdate
                        ? $t("addUser.update")
                        : $t("addUser.addUser")
                    }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </ValidationObserver>

    <CAlert v-if="alertValue" show color="danger">
      <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
        <p v-for="(value, index) in alertValue.valueList" :key="index">
          {{ value }}
        </p>
      </div>
      <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
        <span>{{ $t("cAlert.errorCode") + " : " }}</span
        ><span v-for="(code, index) in alertValue.codeList" :key="index">
          {{ code + " " }}
        </span>
      </div>
      <div v-if="alertValue && alertValue.constructor == String">
        <p>
          {{ alertValue }}
        </p>
      </div>
    </CAlert>
    <!-- <cAlertModal :buttonList="[{name:$t('userStatus.ok'),func:navigateToAllUsers,color:'success'}]" :status=1 :show="showAlertModalUserStatus" :desc="(
       user.firstname+' '+user.lastname +' '+( user.isactive? $t('userStatus.activateSuccess'):$t('userStatus.deactivateSuccess')))">
    </cAlertModal> -->
    <cAlertModal
      v-if="showAlertModal"
      :buttonList="buttonList"
      :status="modalStatus"
      :show="showAlertModal"
      :desc="confirmText"
    ></cAlertModal>
    <cAlertModal
      v-if="showAlertModalUserStatus"
      :buttonList="buttonList"
      :status="modalStatusUserSts"
      :show="showAlertModalUserStatus"
      :desc="confirmText"
    ></cAlertModal>

    <cAlertModal
      v-if="isResetPWconfm"
      :buttonList="[
        {
          name: $t('userStatus.no'),
          func: onRejectResetPW,
          color: 'danger',
        },
        { name: $t('userStatus.yes'), func: onResetPassword, color: 'success' },
      ]"
      :status="2"
      :show="isResetPWconfm"
      :desc="$t('userStatus.pwResetConfirmation', { email: user.emailid })"
    ></cAlertModal>
    <cAlertModal
      v-if="isPwReset"
      :buttonList="[
        {
          name: $t('userStatus.ok'),
          func: onHideResetSucessAlert,
          color: 'success',
        },
      ]"
      :status="1"
      :show="isPwReset"
      :desc="$t('userStatus.mailSuccess', { email: user.emailid })"
    ></cAlertModal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import { mapGetters, mapActions } from "vuex";
import {
  COMPONENT_NAME,
  PATHNAME,
  USERS_TYPE,
  ALERT_MODAL_STATUS,
} from "./../../util/constants";
import cDropDown from "./../../components/form/cDropDown";
import cAlertModal from "./../../components/form/cAlertModal";
import { navigate, updateDataEdited } from "../../router";
import apiUpdateUser from "./../../services/apiUpdateUser";
import apiForgotPassword from "./../../services/apiForgotPassword";

import cStatusButton from "./../../components/form/cStatusButton";
import Logger from "./../../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
} from "./../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
} from "../../util/util";
import BaseConfiguration from "../../config/base";
import cTextInputComponent from "@/components/form/cTextInputComponent";
import cHelpTip from "./../../components/cHelpTip";
import apiCreateUser from "../../services/apiCreateUser";

export default {
  name: COMPONENT_NAME.USER_STATUS,

  components: {
    cAlertModal,
    cDropDown,
    cStatusButton,
    ValidationObserver,
    cTextInputComponent,
    cHelpTip,
  },

  data: () => {
    return {
      rolesServiceURL: SERVICE_URL.GET_USER_ROLES,
      lsUserTypes: [],

      userType: {
        name: null,
        code: null,
      },

      agent: null,

      user: {
        firstname: null,
        lastname: null,
        emailid: null,
        password: null,
        roles: {
          roleName: null,
        },
      },
      isUserUpdate: false,
      alertValue: null,
      showAlertModal: false,
      modalStatus: null,
      buttonList: [],
      confirmText: null,
      modalStatusUserSts: ALERT_MODAL_STATUS.INFO,
      // buttonList: [],
      // confirmText: null,
      showAlertModalUserStatus: false,
      isResetPWconfm: false,
      isPwReset: false,
      isInitialRoleChangedd: false,
    };
  },
  methods: {
    ...mapActions("sAddUser", {
      SET_CREATE_USER_RESPONSE: "SET_CREATE_USER_RESPONSE",
      SET_CREATE_USER_REQUEST: "SET_CREATE_USER_REQUEST",
      SET_UPDATE_USER_RESPONSE: "SET_UPDATE_USER_RESPONSE",
      SET_UPDATE_USER_REQUEST: "SET_UPDATE_USER_REQUEST",
      CLEAR_ADD_USER_STORE: "CLEAR_ADD_USER_STORE",
    }),
    ...mapActions("sManageUsers", {
      SET_ALL_USER_RESPONSE: "SET_ALL_USER_RESPONSE",
    }),

    toggleAlertModalUserStatus() {
      this.showAlertModalUserStatus = !this.showAlertModalUserStatus;
    },
    // showAlert(value) {
    //     this.alertValue = value;
    // },

    async onResetPassword() {
      try {
        if (BaseConfiguration.isDebug) console.log("onResetPassword");
        if (this.selectedUserObj.emailid) {
          await this.getForgotPassword(this.selectedUserObj.emailid);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in onResetPassword",
          err.toString(),
          "onResetPassword",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onHideResetSucessAlert() {
      try {
        if (BaseConfiguration.isDebug) console.log("onHideResetSucessAlert");
        this.isPwReset = false;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in onHideResetSucessAlert",
          err.toString(),
          "onHideResetSucessAlert",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onRejectResetPW() {
      try {
        if (BaseConfiguration.isDebug) console.log("onRejectResetPW");
        this.isResetPWconfm = false;
        this.isPwReset = false;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in onRejectResetPW",
          err.toString(),
          "onRejectResetPW",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onPWResetConfm() {
      try {
        if (BaseConfiguration.isDebug) console.log("onPWResetConfm");
        this.isResetPWconfm = true;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in onPWResetConfm",
          err.toString(),
          "onPWResetConfm",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async getForgotPassword(pMail) {
      try {
        if (BaseConfiguration.isDebug) console.log("getForgotPassword");
        // let retValue;
        let pswdReq = apiForgotPassword.buildRequest(pMail);

        if (pswdReq) {
          let pswdRes = await apiForgotPassword.getResponse(pswdReq);
          if (VALIDATE_SUCCESS_RES(pswdRes)) {
            this.isResetPWconfm = false;
            this.isPwReset = true;

            // this.showAcknowledgeAlert();
          } else {
            let error = getErrorMessage(pswdRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.isResetPWconfm = false;
              this.isPwReset = false;
              getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.AGENT_LOGIN,
          "error in getForgotPassword",
          err.toString(),
          "getForgotPassword",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    async setUserStatus(value) {
      try {
        // this.showAlertModalUserStatus = false;

        if (this.user) {
          this.user.isactive = value; // this.user.status.value;
        }
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in  set User Status",
          err.toString(),
          "setUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },
    navigateToAllUsers() {
      try {
        this.showAlertModalUserStatus = false;

        navigate(PATHNAME.MANAGE_USERS);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.USER_STATUS,
          "error in  navigate To All Users",
          err.toString(),
          "navigate To All Users",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    // eslint-disable-next-line no-unused-vars
    onEdit(p) {
      try {
        if (BaseConfiguration.isDebug) console.log("onEdit");
        updateDataEdited(true);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in onEdit",
          err.toString(),
          "onEdit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getModalBtnLs() {
      return [
        {
          name: this.$t("addUser.ok"),
          func: this.navigateToAllUsers,
          color: "success",
        },
      ];
    },
    onRoleChange(pSelectedRole) {
      if (pSelectedRole) {
        this.user.roles = pSelectedRole;
      }
      if (this.isInitialRoleChanged) {
        updateDataEdited(true);
      }
      this.isInitialRoleChanged = true;
    },

    showAlert(value) {
      this.alertValue = value;
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },

    showModal(pStatus) {
      try {
        // let retValue;
        this.showAlertModal = true;
        if (pStatus) {
          this.modalStatus = pStatus;
        } else {
          this.modalStatus = this.isUserUpdate
            ? ALERT_MODAL_STATUS.INFO
            : ALERT_MODAL_STATUS.SUCCESS;
        }
        let desc = null;
        if (this.modalStatus == ALERT_MODAL_STATUS.INFO) {
          this.buttonList = [
            {
              name: this.$t("addUser.no"),
              func: this.toggleAlertModal,
              color: "danger",
            },
            {
              name: this.$t("addUser.yes"),
              func: this.onAddUserFormSubmit,
              color: "success",
            },
          ];
          desc = this.$t("addUser.confirmUpdate", {
            fname: this.user.firstname,
            lname: this.user.lastname,
          });
        } else if (this.modalStatus == ALERT_MODAL_STATUS.SUCCESS) {
          this.buttonList = [
            {
              name: this.$t("addUser.ok"),
              func: this.navigateToAllUsers,
              color: "danger",
            },
          ];
          desc = this.isUserUpdate
            ? this.$t("addUser.updateSuccess", {
                fname: this.user.firstname,
                lname: this.user.lastname,
              })
            : this.$t("addUser.addSuccess", {
                fname: this.user.firstname,
                lname: this.user.lastname,
              });
        }
        this.confirmText = desc;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in showModal",
          err.toString(),
          "showModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    async onAddUserFormSubmit() {
      try {
        if (this.isUserUpdate) {
          let updateUserStatus = await this.getUpdateUserStatus(this.user);
          if (updateUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        } else {
          let createUserStatus = await this.getCreateUserStatus(this.user);
          if (createUserStatus) this.showModal(ALERT_MODAL_STATUS.SUCCESS);
        }
        updateDataEdited(false);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  on Add User Form Submit",
          err.toString(),
          "onAddUserFormSubmit",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    async getCreateUserStatus(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;

        let createUserRequest = apiCreateUser.buildRequest(pUser);

        if (createUserRequest != null) {
          this.SET_CREATE_USER_REQUEST(createUserRequest);

          let createUserResponse = await apiCreateUser.getResponse(
            createUserRequest
          );
          if (VALIDATE_SUCCESS_RES(createUserResponse)) {
            returnValue = createUserResponse.data;
            this.showAlert(null);
            this.SET_CREATE_USER_RESPONSE(returnValue);
            this.clearAllUserRes();
          } else {
            let error = getErrorMessage(createUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Create User Status",
          err.toString(),
          "getCreateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },

    clearAllUserRes() {
      this.SET_ALL_USER_RESPONSE(null);
    },
    async getUpdateUserStatus(pUser) {
      let returnValue;

      try {
        this.showAlertModal = false;
        let updateUserRequest = apiUpdateUser.buildRequest(pUser);
        if (updateUserRequest) {
          this.SET_UPDATE_USER_REQUEST(updateUserRequest);
          let updateUserResponse = await apiUpdateUser.getResponse(
            updateUserRequest
          );

          if (VALIDATE_SUCCESS_RES(updateUserResponse)) {
            //succ
            returnValue = updateUserResponse.data;
            this.SET_UPDATE_USER_RESPONSE(returnValue);
            this.showAlert(null);

            this.clearAllUserRes();
          } else {
            //err

            let error = getErrorMessage(updateUserResponse);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.ADD_USER,
          "error in  get Update User Status",
          err.toString(),
          "getUpdateUserStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }

      return returnValue;
    },
    // navigateToAllUsers() {
    //   try {
    //    goBack();
    //   } catch (e) {
    //     let body = Logger.buildRequest(
    //       FILE_NAME.ADD_USER,
    //       "error in  navigate To All Users",
    //       err.toString(),
    //       "navigate To All Users"
    //     );
    //     Logger.getResponse(body);
    //   }
    // },
    getPwdRules() {
      let returnValue;
      if (this.isUserUpdate) {
        returnValue = "minmax:8,20|number";
      } else {
        returnValue = "noSpaceAllowed|required|validPwd|minmax:8,20";
      }
      return returnValue;
    },
  },

  computed: {
    ...mapGetters("sAddUser", {
      selectedUserObj: "GET_SELECTED_USER",
    }),

    ...mapGetters("sSysLogin", { adminSignIn: "GET_ADMIN_SIGNIN_RES" }),
  },

  // computed: {
  //     ...mapGetters("sAddUser", {
  //         selectedUserObj: "GET_SELECTED_USER"
  //     }),

  // },
  created() {
    try {
      if (this.selectedUserObj != null) {
        this.isUserUpdate = true;
        this.user = this.selectedUserObj;

        this.user.roles = {
          roleName: this.selectedUserObj.roles[0],
        };
        this.user.status = {
          name: this.selectedUserObj.isactive
            ? this.$t("userStatus.active")
            : this.$t("userStatus.inactive"),
          value: this.selectedUserObj.isactive,
        };
      } else {
        this.isUserUpdate = false;
      }
      this.lsUserTypes = [
        {
          name: this.$t("addUser.system"),
          code: USERS_TYPE.SYSTEM,
        },
      ];
      this.userType.code = this.lsUserTypes[0].code;
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.USER_STATUS,
        "error in  Creating  User Status",
        err.toString(),
        "Created",
        LOG_TYPE.ERROR
      );

      Logger.getResponse(body);
    }
  },

  beforeCreate() {
    addClassToBody(COMPONENT_NAME.USER_STATUS);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>

<style>
.buttonspace {
  margin-left: 16px;
}

.footer {
  margin: 10px;
}

.active {
  /*color: green;*/
  /*padding-left: 5px;*/
}
body.UserStatus .textbox .row {
  display: -ms-flexbox;
  display: block;
  min-height: 0px;
  -ms-flex-align: center;
  /* align-items: center; */
  /* padding: 0px 15px; */
  /* max-width: 550px; */
  /* border-radius: 3px; */
  /* margin: auto; */
  /* margin-bottom: 5px; */
  /* margin-top: 0px; */
  box-shadow: 0px 0px 0px 0px;
  margin: 0px;
  margin-bottom: 16px;
}
</style>
